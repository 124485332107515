import React from "react"

import Layout from "../components/layout"
import Title from "../components/elements/title"
import BlogFeed from "../components/blogFeed"
import SEO from "../components/seo"

const BlogPage = () => {

  return (
    <Layout>
      <SEO title="Blog" />
      <Title size="h2">Blog</Title>
      <BlogFeed full />
    </Layout>
  )
}

export default BlogPage
